<template>
	<div class="doctor-list">

		<el-card class="filter-container" shadow="never">

			<div style="margin-top: 15px">
				<el-form :inline="true" :model="value" size="small" >
					<el-form-item label=" ">
						<el-button type="primary" size="small">
							备份当前数据
						</el-button>
						<el-button  type="primary" size="small">
							优化数据库
						</el-button>
						<el-button  type="primary" size="small">
							修复数据库
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-card>

<!-- 		<el-card style="margin-top: 20px;" shadow="never">
			<el-row justify="space-between" type="flex">
				<el-col><i class="el-icon-tickets"></i><span>数据列表</span></el-col>
				<el-col :xs="20" :sm="20" :md="18" :lg="6" :xl="4">
					<el-button class="btn-add" size="mini">
						保存排序
					</el-button>
					<el-button class="btn-add" size="mini">
						删除
					</el-button>
					<el-button class="btn-add" size="mini">
						添加
					</el-button>
				</el-col>
			</el-row>
		</el-card> -->
		<el-card style="margin-top: 20px;" shadow="never">
			<el-table :data="tableData" style="width: 100%;">
				<el-table-column type="selection" width="55"  align="center">
				</el-table-column>
			
				<el-table-column label="表名" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.date }}</span>
					</template>
				</el-table-column>
				<el-table-column label="数据量" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.date }}</span>
					</template>
				</el-table-column>

				<el-table-column label="数据大小" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.date }}</span>
					</template>
				</el-table-column>

				<el-table-column label="创建时间" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.date }}</span>
					</template>
				</el-table-column>

				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<p>
							<el-button size="mini" type="text">优化表</el-button>
							<el-button size="mini" type="text">修复表</el-button>
							<el-button size="mini" type="text">备份表</el-button>
						</p>
						
					</template>
				</el-table-column>
			</el-table>
		</el-card>



		<el-pagination :hide-on-single-page="hideonsinglepage" background :total="5" layout="prev, pager, next">
		</el-pagination>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					name: '',
				},
				tableData: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1517 弄'
				}, {
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1519 弄'
				}, {
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1516 弄'
				}],
				src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
				Switch: false,
				hideonsinglepage: false
			}
		},
		methods: {
			onSubmit() {
				console.log('submit!');
			}
		}
	}
</script>

<style lang="less" scoped>


</style>
